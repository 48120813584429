.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  padding: 20px;
  border: 1px solid #7D7878;
  background-color: #464646;
  color: #FFF;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: 280px;
  justify-content: center;
}
